var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-popover',{attrs:{"placement":"rightTop"}},[_c('template',{slot:"content"},[_vm._v(" Sisa waktu untuk melakukan edit. ")]),_c('div',{staticClass:"limittime"},[_c('div',{staticClass:"w-title"},[(_vm.info == null)?_c('h3',[_c('a-icon',{attrs:{"type":"clock-circle"}}),_vm._v(" Tersisa "),_c('a-statistic-countdown',{staticStyle:{"font-size":"10px !important"},attrs:{"value":_vm.countDown,"format":"HH:mm:ss"},on:{"finish":_vm.onFinish}})],1):_c('h3',[_vm._v(_vm._s(_vm.info))])])])],2),_c('div',{staticClass:"allcard",staticStyle:{"height":"calc(100vh - (223px))"}},[_c('a-skeleton',{attrs:{"loading":_vm.loadingpt}},[_c('div',{staticClass:"ContentHeader"},[_c('div',{staticClass:"HeaderAll"},[_vm._t("onlybreadcrumb")],2)]),_c('simplebar',{staticClass:"simplebarcustom",attrs:{"data-simplebar-auto-hide":"false"}},[_c('div',{staticClass:"formpeserta"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.Submit}},[_c('a-row',{attrs:{"gutter":[15, 15]}},[_c('a-col',{attrs:{"lg":8}},[_c('div',{ref:"heightCardForm",staticClass:"cardForm",style:(`min-height:${_vm.heightCardForm}px`)},[_c('h1',[_vm._v("Informasi Peserta")]),_c('a-form-item',{attrs:{"label":"Nama Peserta"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'nama_peserta',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Field ini harus diisi',
                            },
                          ],
                        },
                      ]),expression:"[\n                        'nama_peserta',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Field ini harus diisi',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"autocomplete":"off","placeholder":"Input disini"},on:{"change":_vm.namaPeserta}})],1),_c('a-form-item',{attrs:{"label":"Tgl Join"}},[_c('div',[(_vm.data.tgl_join != null)?_c('a-icon',{attrs:{"type":"calendar"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.data.tgl_join == null ? "-" : _vm.data.tgl_join)+" ")],1)]),_c('a-form-item',{attrs:{"label":"Nama Karyawan"}},[_c('div',[_vm._v(_vm._s(_vm.namaKaryawans))])]),_c('a-form-item',{attrs:{"label":"Tanggal Lahir"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'tgl_lahir',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Field ini harus diisi',
                            },
                          ],
                        },
                      ]),expression:"[\n                        'tgl_lahir',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Field ini harus diisi',\n                            },\n                          ],\n                        },\n                      ]"}],staticStyle:{"width":"100%"},attrs:{"autocomplete":"off","placeholder":"Input disini","format":"DD MMM YYYY"}})],1),_c('a-form-item',{attrs:{"label":"NIK"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['nik']),expression:"['nik']"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1),_c('a-row',{attrs:{"gutter":[15, 15]}},[_c('a-col',{attrs:{"lg":12}},[_c('a-form-item',{attrs:{"label":"Jenis Kelamin"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'jenis_kelamin',
                            {
                              rules: [
                                {
                                  required: true,
                                },
                              ],
                            },
                          ]),expression:"[\n                            'jenis_kelamin',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                },\n                              ],\n                            },\n                          ]"}],attrs:{"placeholder":"Jenis Kelamin"},on:{"change":_vm.jenisKelamin}},[_c('a-select-option',{attrs:{"value":"M"}},[_c('a-icon',{attrs:{"type":"man"}}),_vm._v(" Pria")],1),_c('a-select-option',{attrs:{"value":"F"}},[_c('a-icon',{style:({ color: '#DF5684' }),attrs:{"type":"woman"}}),_vm._v(" Wanita")],1)],1)],1)],1),_c('a-col',{attrs:{"lg":12}},[_c('a-form-item',{attrs:{"label":"Status"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'status',
                            {
                              rules: [
                                {
                                  required: true,
                                },
                              ],
                            },
                          ]),expression:"[\n                            'status',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                },\n                              ],\n                            },\n                          ]"}],attrs:{"placeholder":"Status"},on:{"change":_vm.StatusKaryawan}},[_c('a-select-option',{attrs:{"value":"E"}},[_vm._v(" Employee")]),_c('a-select-option',{attrs:{"value":"S"}},[_vm._v(" Spouse")]),_c('a-select-option',{attrs:{"value":"C"}},[_vm._v(" Child")])],1)],1)],1)],1),(_vm.changeplan)?_c('div',[_c('a-form-item',{attrs:{"label":"Kategori Usia"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'kategori_usia',
                          {
                            rules: [
                              {
                                required: true,
                              },
                            ],
                          },
                        ]),expression:"[\n                          'kategori_usia',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"placeholder":"Kategori Usia"},on:{"change":_vm.kategoriUsia}},_vm._l((_vm.usia),function(item,i){return _c('a-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.title))])}),1)],1),_c('a-form-item',{attrs:{"label":"Plan"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'plan',
                          {
                            rules: [
                              {
                                required: true,
                              },
                            ],
                          },
                        ]),expression:"[\n                          'plan',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"placeholder":"Plan"}},_vm._l((_vm.plan),function(item,i){return _c('a-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.benefit.substring(0, item.benefit.length - 2)))])}),1)],1),_c('a-button',{attrs:{"type":"link-custom"},on:{"click":_vm.cancelEditPlan}},[_c('a-icon',{attrs:{"type":"undo"}}),_vm._v(" Undo Plan ")],1)],1):_c('div',[_c('div',{staticClass:"_thr profileplan"},[_c('label',[_vm._v("Plan")]),_c('div',[_c('div',[_c('ul',_vm._l((_vm.existingplan.filter(
                                (key) => key.plan != null
                              )),function(item,i){return _c('li',{key:i,on:{"click":function($event){_vm.planshow(
                                  _vm.$route.query.id_job_order,
                                  item.plan.split(' ')[0],
                                  item.plan.split(' ')[2]
                                )}}},[_c('img',{attrs:{"src":require(`@/assets/img/icon/${item.plan
                                    .split(' ')[0]
                                    .toLowerCase()}.svg`)}}),_vm._v(" "+_vm._s(item.plan)+" ")])}),0)]),_c('div',{staticStyle:{"margin-left":"10px","margin-top":"-10px"}},[_c('a-button',{staticStyle:{"padding":"9px !important","height":"41px !important"},attrs:{"type":"primary"},on:{"click":_vm.editbenefit}},[_c('a-icon',{attrs:{"type":"edit"}}),_vm._v(" Edit Plan")],1)],1)])])])],1)]),_c('a-col',{attrs:{"lg":8}},[_c('div',{ref:"heightCardForm",staticClass:"cardForm",style:(`min-height:${_vm.heightCardForm}px`)},[_c('h1',[_vm._v("Bank Account")]),_c('a-form-item',{attrs:{"label":"Nama Bank"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['nama_bank']),expression:"['nama_bank']"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1),_c('a-form-item',{attrs:{"label":"No.Rekening"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['no_rekening']),expression:"['no_rekening']"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1),_c('a-form-item',{attrs:{"label":"Nama Pemilik Rekening"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['nama_rekening']),expression:"['nama_rekening']"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1),_c('a-form-item',{attrs:{"label":"No.Telp"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['no_telp']),expression:"['no_telp']"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1),_c('a-form-item',{attrs:{"label":"Alamat Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'email',
                        {
                          rules: [
                            {
                              type: 'email',
                              message: 'Format email masih salah',
                            },
                          ],
                        },
                      ]),expression:"[\n                        'email',\n                        {\n                          rules: [\n                            {\n                              type: 'email',\n                              message: 'Format email masih salah',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1)],1)]),_c('a-col',{attrs:{"lg":8}},[_c('div',{ref:"heightCardForm",staticClass:"cardForm",style:(`min-height:${_vm.heightCardForm}px`)},[_c('h1',[_vm._v("Company Information")]),_c('a-form-item',{attrs:{"label":"Nama Group"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'group',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Field ini harus diisi',
                            },
                          ],
                        },
                      ]),expression:"[\n                        'group',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Field ini harus diisi',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"autocomplete":"off","placeholder":"Input disini","disabled":true}})],1),_c('a-form-item',{attrs:{"label":"Nama Perusahaan"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'nama_perusahaan',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Field ini harus diisi',
                            },
                          ],
                        },
                      ]),expression:"[\n                        'nama_perusahaan',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Field ini harus diisi',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"autocomplete":"off","placeholder":"Input disini","disabled":true}},_vm._l((_vm.perusahaan),function(item,i){return _c('a-select-option',{key:i,attrs:{"value":item.id}},[_vm._v(_vm._s(item.nama_perusahaan))])}),1)],1),_c('a-form-item',{attrs:{"label":"Cabang"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['cabang']),expression:"['cabang']"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1),_c('a-form-item',{attrs:{"label":"Sub Cabang I"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sub_cabang_1']),expression:"['sub_cabang_1']"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1),_c('a-form-item',{attrs:{"label":"Sub Cabang II"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sub_cabang_2']),expression:"['sub_cabang_2']"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1),_c('a-form-item',{attrs:{"label":"Keterangan"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['keterangan']),expression:"['keterangan']"}],attrs:{"autocomplete":"off","placeholder":"Input disini"}})],1)],1)]),_c('a-col',{attrs:{"lg":24}},[_c('div',{staticClass:"submitcard",staticStyle:{"border":"1px solid #d8ddec"}},[_c('a-button',{attrs:{"type":"default"},on:{"click":_vm.batalkan}},[_vm._v("Batalkan")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v("Submit")])],1)])],1)],1)],1)])],1)],1),_c('Confirm',{ref:"Confirm",on:{"fetch":_vm.redirect}}),_c('BenefitPlan',{ref:"plan"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }